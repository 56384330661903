var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pt-5"},[_c('v-col',{staticClass:"px-4 mt-4",attrs:{"cols":"12","md":"10"}},[_c('h2',{staticClass:"white--text font-weight-light"},[_vm._v(" Willkommen, "),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.user.displayName)+" ")])])])],1),(_vm.teams.length == 0)?_c('v-row',{staticStyle:{"max-height":"80vh","overflow-y":"scroll"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" Du hast derzeit noch kein Team angelegt. Bitte lege zuerst ein Team an. ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('cardbutton',{attrs:{"title":'Team erstellen',"icon":'mdi-plus',"textcolor":_vm.template.colors.inline_primary_text,"bgcolor":_vm.template.colors.primary,"upper":""},on:{"click":function($event){_vm.newteam = true}}})],1)],1):_vm._e(),(_vm.teams.length > 0)?_c('v-row',{staticStyle:{"max-height":"80vh","overflow-y":"scroll"},attrs:{"justify":"center"}},[(_vm.veranstaltungen.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Deine nächsten Veranstaltungen:")])]):_vm._e(),(_vm.veranstaltungen.length == 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Du hast derzeit keine zukünftigen Veranstaltungen angelegt.")])]):_vm._e(),_vm._l((_vm.veranstaltungen),function(v){return _c('v-col',{key:v.id,attrs:{"cols":"12"}},[_c('cardveranstaltung',{attrs:{"abgesagt":v.abgesagt,"art":v.art,"gastmannschaft":v.gastmannschaft,"id":v.id,"mannschaft":v.mannschaft,"name":v.name,"beginnuhrzeit":v.beginn.datum == v.ende.datum
            ? v.beginn.uhrzeit
            : v.beginn.datum == _vm.timer.datum
            ? v.beginn.uhrzeit
            : v.beginn.datum < _vm.timer.datum && v.ende.datum > _vm.timer.datum
            ? 'GANZTÄGIG'
            : 'bis ' + v.ende.uhrzeit,"beginndatum":v.beginn.datum == v.ende.datum
            ? v.beginn.datum
            : v.beginn.datum <= _vm.timer.datum && v.ende.datum >= _vm.timer.datum
            ? _vm.timer.datum
            : v.ende.datum}})],1)}),(_vm.veranstaltungen.length == 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('cardbutton',{attrs:{"title":'Veranstaltung erstellen',"icon":'mdi-calendar-plus',"textcolor":_vm.template.colors.block_text,"bgcolor":_vm.template.colors.blocks,"upper":""},on:{"click":function($event){_vm.newveranstaltung = true}}})],1):_vm._e(),_c('v-col',{staticClass:"mt-5 pb-5",attrs:{"cols":"12"}},[_c('cardbutton',{attrs:{"title":'Alle Veranstaltungen',"icon":'mdi-calendar',"textcolor":_vm.template.colors.inline_primary_text,"bgcolor":_vm.template.colors.primary,"chev":"","upper":"","to":'/start'}})],1)],2):_vm._e(),(_vm.notification.includes('2G'))?_c('GGNotification',{on:{"close":function($event){_vm.notification = []}}}):_vm._e(),(_vm.newteam)?_c('CreateTeam',{on:{"closing":function($event){return _vm.create_team_closed()}}}):_vm._e(),(_vm.newveranstaltung)?_c('CreateVeranstaltung',{on:{"closing":function($event){_vm.newveranstaltung = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }