<template>
  <v-dialog
    content-class="rounded-xl"
    value="true"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-color="grey darken-4"
    :overlay-opacity="0.9"
    :max-width="$vuetify.breakpoint.smAndDown ? undefined : '800'"
  >
    <v-card
      class="rounded-xl"
      :color="template.colors.primary"
      style="min-height: 500px;"
    >
      <v-row justify="center">
        <v-col cols="12" class="text-center" align-self="center">
          <h3>+++ WIR HABEN EIN UPDATE FÜR EUCH +++</h3>
          <h1 class="text-uppercase">2G und 2G-Plus Funktionen</h1>
        </v-col>
        <v-col cols="10" lg="5">
          <v-card
            class="rounded-xl"
            :color="template.colors.blocks"
            :dark="template.isDark(template.colors.blocks)"
            :light="!template.isDark(template.colors.blocks)"
          >
            <v-card-title>
              <v-icon>mdi-shield-check</v-icon>
              <v-icon small>mdi-plus</v-icon>
              <v-icon class="mr-3">mdi-shield-off-outline</v-icon>
              TEAM-ANWESENHEIT
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle :style="`color: ${template.colors.block_text}`">
              Sehe direkt in der TEAM-ANWESENHEIT welche Personen an deiner
              Veranstaltung teilnehmen können. Denn es können nur Personen als
              anwesend markiert werden, wenn sie immunisiert sind. Du kannst
              hier auch direkt einen Immunisierungsstatus festlegen, sollte bei
              einer Person als nicht immunisiert angezeigt werden. Bei Spielen
              können zudem auch nicht immunisierte Personen als getestet
              markiert werden, sollten die Personen einen negativen PCR-Test
              vorgezeigt haben.
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="10" lg="5">
          <v-card
            class="rounded-xl"
            :color="template.colors.blocks"
            :dark="template.isDark(template.colors.blocks)"
            :light="!template.isDark(template.colors.blocks)"
          >
            <v-card-title>
              <v-icon>mdi-less-than</v-icon>
              16
              <v-icon>mdi-equal</v-icon>
              Kein 2G
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle :style="`color: ${template.colors.block_text}`">
              Für ALLE Personen, die jünger als 16 sind, gilt derzeit kein 2G!
              Daher kannst du auch für deine Mannschaften individuell festlegen,
              ob alle Sportler unter 16 sind. Alternativ kann ein Geburtsdatum
              für jede Person eingegeben werden. Sollte eine Person unter 16
              sein, wird sie automatisch von der 2G-Regel ausgeschlossen.
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="10" lg="5">
          <v-card
            class="rounded-xl"
            :color="template.colors.blocks"
            :dark="template.isDark(template.colors.blocks)"
            :light="!template.isDark(template.colors.blocks)"
          >
            <v-card-title>
              ZUSCHAUER CHECK-IN
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle :style="`color: ${template.colors.block_text}`">
              Ein CheckIn für Zuschauer ist nur noch möglich, wenn der Zuschauer
              zuvor bestätigt, dass er immunisiert ist! Das Gleiche gilt beim
              Hinzufügen von Zuschauern über die Zuschauerkontrolle: Auch hier
              muss dann vor dem CheckIn bestätigt werden, dass die Person
              immunisiert ist.
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="10" lg="5">
          <v-card
            class="rounded-xl"
            :color="template.colors.blocks"
            :dark="template.isDark(template.colors.blocks)"
            :light="!template.isDark(template.colors.blocks)"
          >
            <v-card-title>
              WIE KANN ICH DIE FUNKTION AKTIVIEREN?
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle :style="`color: ${template.colors.block_text}`">
              Ein User mit der Rolle "Vorstand" muss die 2G oder 2G-Plus Regel
              im Hygienekonzept des Vereins aktivieren. Sobald dies geschehen
              ist, werden für den gesamten Verein die 2G-Funktionen genutzt.
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="10">
          <v-row justify="space-around">
            <v-btn
              large
              rounded
              href="https://www.flvw.de/fileadmin/content/medienarchiv/1_NEWS/Verband/Dokumente/Coronaschutzverordnung_NRW_Stand_24_11_2021.pdf"
              target="_blank"
              v-if="false"
            >
              MEHR INFOS ZUR 2G-REGELUNG
            </v-btn>
            <v-btn large rounded @click="close()">Hinweis schliessen</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: '2G-Funktion Info',
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  methods: {
    close() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .update({
          update_2g_regel: true,
        })
        .then(() => {
          this.$emit('close')
        })
    },
  },
}
</script>
