<template>
  <div>
    <v-row class="pt-5">
      <v-col cols="12" md="10" class="px-4 mt-4">
        <h2 class="white--text font-weight-light">
          Willkommen,
          <br />
          <span class="font-weight-bold">
            {{ user.displayName }}
          </span>
        </h2>
      </v-col>
    </v-row>
    <!-- <v-row v-if="user ? user.ga.anfragen.open.length > 0 : false">
      <v-col cols="12">
        <v-alert
          class="rounded-xl"
          prominent
          type="error"
          :color="template.colors.danger"
        >
          <v-row align="center">
            <v-col class="grow">
              <b>
                Es gibt offene Anfragen nach Kontaktlisten vom Gesundheitsamt!
              </b>
              <small v-if="user.data.rolle != 'Vorstand'">
                <br />
                Bitte kontaktiere ein Vorstandsmitglied, damit die Anfrage
                beantwortet werden kann!
              </small>
            </v-col>
            <v-col class="shrink" v-if="user.data.rolle == 'Vorstand'">
              <v-btn
                rounded
                to="/ga/anfragen"
                v-if="user.data.rolle == 'Vorstand'"
              >
                Anfragen beantworten
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row> -->
    <v-row
      justify="center"
      style="max-height: 80vh; overflow-y: scroll;"
      v-if="teams.length == 0"
    >
      <v-col cols="12">
        <h3>
          Du hast derzeit noch kein Team angelegt. Bitte lege zuerst ein Team
          an.
        </h3>
      </v-col>
      <v-col cols="12">
        <cardbutton
          :title="'Team erstellen'"
          :icon="'mdi-plus'"
          :textcolor="template.colors.inline_primary_text"
          :bgcolor="template.colors.primary"
          upper
          @click="newteam = true"
        />
      </v-col>
    </v-row>
    <v-row
      justify="center"
      style="max-height: 80vh; overflow-y: scroll;"
      v-if="teams.length > 0"
    >
      <v-col cols="12" v-if="veranstaltungen.length > 0">
        <h3>Deine nächsten Veranstaltungen:</h3>
      </v-col>
      <v-col cols="12" v-if="veranstaltungen.length == 0">
        <h3>Du hast derzeit keine zukünftigen Veranstaltungen angelegt.</h3>
      </v-col>
      <v-col cols="12" v-for="v in veranstaltungen" :key="v.id">
        <cardveranstaltung
          :abgesagt="v.abgesagt"
          :art="v.art"
          :gastmannschaft="v.gastmannschaft"
          :id="v.id"
          :mannschaft="v.mannschaft"
          :name="v.name"
          :beginnuhrzeit="
            v.beginn.datum == v.ende.datum
              ? v.beginn.uhrzeit
              : v.beginn.datum == timer.datum
              ? v.beginn.uhrzeit
              : v.beginn.datum < timer.datum && v.ende.datum > timer.datum
              ? 'GANZTÄGIG'
              : 'bis ' + v.ende.uhrzeit
          "
          :beginndatum="
            v.beginn.datum == v.ende.datum
              ? v.beginn.datum
              : v.beginn.datum <= timer.datum && v.ende.datum >= timer.datum
              ? timer.datum
              : v.ende.datum
          "
        />
      </v-col>
      <v-col cols="12" v-if="veranstaltungen.length == 0">
        <cardbutton
          :title="'Veranstaltung erstellen'"
          :icon="'mdi-calendar-plus'"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          @click="newveranstaltung = true"
        />
      </v-col>
      <v-col cols="12" class="mt-5 pb-5">
        <cardbutton
          :title="'Alle Veranstaltungen'"
          :icon="'mdi-calendar'"
          :textcolor="template.colors.inline_primary_text"
          :bgcolor="template.colors.primary"
          chev
          upper
          :to="'/start'"
        />
      </v-col>
    </v-row>
    <GGNotification
      v-if="notification.includes('2G')"
      @close="notification = []"
    />
    <CreateTeam v-if="newteam" v-on:closing="create_team_closed()" />
    <CreateVeranstaltung
      v-if="newveranstaltung"
      v-on:closing="newveranstaltung = false"
    />
  </div>
</template>

<script>
import cardveranstaltung from '../../elements/cards/card-veranstaltung'
import cardbutton from '../../elements/cards/card-button'
import { mapGetters } from 'vuex'
//import firebase from '../../firebaseConfig'
import store from '../../store'
import CreateTeam from './team/create'
import CreateVeranstaltung from './veranstaltung/create'
import router from '../../routes/index'
import GGNotification from './GGNotification.vue'

export default {
  name: 'Start',
  data() {
    return {
      newveranstaltung: false,
      veranstaltungen: [],
      veranst_date: [],
      future_dates: [],
      past_dates: [],
      todays_dates: [],
      teams: [],
      newteam: false,
      date: '',
      tabs: 1,
      add_to_home: false,
      multidelete: false,
      notify: false,
      notification: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  mounted() {
    if (this.user) {
      if (this.user.data) {
        if (this.user.data.rolle) {
          if (
            this.user.data.rolle == 'Zuschauer' ||
            this.user.data.rolle == 'Schiedsrichter' ||
            (this.user.data.verband != this.template.var.verband &&
              !router.currentRoute.fullPath
                .toString()
                .includes('/veranstaltung/'))
          ) {
            if (this.user.fullfill) {
              router.push('/profil-vervollstaendigen')
            } else {
              router.push('/user/check-ins')
            }
          }
        }
        if (!this.user.data.update_2g_regel) {
          this.notification.push('2G')
        }
      } else {
        router.push('/login')
      }
    } else {
      router.push('/login')
    }
    if (this.user.data.rolle == 'Vorstand') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .orderBy('name')
        .onSnapshot(
          (querySnap) => {
            this.teams = []
            querySnap.forEach((doc) => {
              this.teams.push(doc.data())
              var index = this.teams.length - 1
              this.teams[index].id = doc.id
            })
          },
          (error) => {
            console.log(error)
          },
        )
    } else if (this.user.data.rolle == 'Trainer') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .where('name', 'in', this.user.data.team)
        .onSnapshot(
          (querySnap) => {
            this.teams = []
            querySnap.forEach((doc) => {
              this.teams.push(doc.data())
              var index = this.teams.length - 1
              this.teams[index].id = doc.id
            })
          },
          (error) => {
            console.log(error)
          },
        )
    } else if (this.user.data.rolle == 'Zuschauerkontrolle') {
      router.push('/zuschauerkontrolle')
    }
    store.dispatch('setTemplateBackgroundGradient', {
      start: '25%',
      end: '80%',
    })
    if (
      this.user.data.notified != 'v2' &&
      this.user.data.rolle != 'Zuschauer'
    ) {
      this.notify = 'update2.0'
    }
    var generatedDates = Zeit.generateDays(7, 0)
    var generatedDates1 = Zeit.generateDays(7, 2)
    var tage = generatedDates.tage
    var tage1 = generatedDates1.tage
    var mehrtaegigeVeranstaltungen = ['Wettkampf', 'Versammlung']
    var i = 0
    var max = 3
    if (this.user.data.rolle == 'Trainer') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .where('beginn.datum', 'in', tage1)
        .orderBy('beginn.uhrzeit', 'asc')
        .onSnapshot(
          (querySnap) => {
            this.veranstaltungen = []
            querySnap.forEach((doc) => {
              if (
                i < max &&
                this.user.data.team.includes(doc.data().mannschaft) &&
                ((mehrtaegigeVeranstaltungen.includes(doc.data().art) &&
                  tage.includes(doc.data().beginn.datum)) ||
                  doc.data().beginn.datum >= this.timer.datum)
              ) {
                this.veranstaltungen.push(doc.data())
                var index = this.veranstaltungen.length - 1
                this.veranstaltungen[index].id = doc.id
                i++
              }
            })
          },
          (error) => {
            console.log(error)
          },
        )
    } else if (this.user.data.rolle == 'Vorstand') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .where('beginn.datum', 'in', tage1)
        .orderBy('beginn.uhrzeit', 'asc')
        .onSnapshot(
          (querySnap) => {
            this.veranstaltungen = []
            querySnap.forEach((doc) => {
              if (
                i < max &&
                ((mehrtaegigeVeranstaltungen.includes(doc.data().art) &&
                  tage.includes(doc.data().beginn.datum)) ||
                  doc.data().beginn.datum >= this.timer.datum)
              ) {
                this.veranstaltungen.push(doc.data())
                var index = this.veranstaltungen.length - 1
                this.veranstaltungen[index].id = doc.id
                i++
                console.log(i)
              }
            })
          },
          (error) => {
            console.log(error)
          },
        )
    }
  },
  components: {
    cardveranstaltung,
    cardbutton,
    CreateTeam,
    CreateVeranstaltung,
    GGNotification,
  },
  methods: {
    create_team_closed() {
      this.newteam = false
      router.push('/teams')
    },
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
  },
}
</script>
