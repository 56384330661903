<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Team hinzufügen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" class="mt-4">
        <v-col cols="10" lg="8" class="px-2">
          <v-text-field
            v-model="t.verein.name"
            rounded
            filled
            readonly
            disabled
            label="Verein"
          ></v-text-field>
          <v-text-field
            v-model="t.name"
            rounded
            filled
            label="Name des Teams"
          ></v-text-field>
          <v-select
            v-model="t.abteilung"
            :items="user.data.abteilungen"
            item-text="name"
            item-value="name"
            label="Abteilung"
            rounded
            filled
            v-if="user.data.abteilungen.length > 0"
          ></v-select>
          <v-btn
            class="my-4"
            block
            rounded
            large
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            @click="create()"
            :disabled="!t.name"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            Hinzufügen
          </v-btn>
          <v-btn
            class="my-4"
            block
            rounded
            large
            outlined
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            @click="closing()"
          >
            Abbrechen
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'Team-erstellen',
  data() {
    return {
      t: {
        name: '',
        verein: {
          name: '',
          logo: '',
        },
        abteilung: '',
      },
    }
  },
  mounted() {
    this.t.verein = this.user.data.verein
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
    create() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.t.name)
        .set({
          name: this.t.name,
          verein: this.t.verein,
          abteilung: this.t.abteilung,
        })
        .then((doc) => {
          this.closing()
        })
        .catch((error) => {
          console.log('Es ist ein Fehler aufgetreten!', error)
        })
    },
  },
}
</script>
